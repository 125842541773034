import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ArticleTags } from '../interfaces/article';

const API_URl = environment.apiBaseUrl + 'tag';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private http: HttpClient) {}

  getTags(): Observable<ArticleTags[]> {
    return this.http.get<ArticleTags[]>(API_URl);
  }

  getTagById(id: Number): Observable<ArticleTags> {
    return this.http.get<ArticleTags>(API_URl + '/' + id);
  }
}
