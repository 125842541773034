<div #inputWatcher>
  <div *ngIf="!isEditing" class="article-tags" (click)="changeMode()">
    <mat-icon class="editIcon" *ngIf="editMode" (click)="changeMode()"
      >edit</mat-icon
    >
    <div
      class="tag"
      *ngFor="let tag of tags"
      [ngStyle]="{ background: tag.color }"
    >
      {{ tag.name }}
    </div>
  </div>
  <div class="input-wrapper" *ngIf="isEditing">
    <mat-icon class="editIcon cancel" (click)="cancel()">close</mat-icon>
    <mat-select [(ngModel)]="pendingValue" multiple>
      <mat-select-trigger class="article-tags">
        <div
          class="tag"
          *ngFor="let tag of pendingValue"
          [ngStyle]="{ background: tag.color }"
        >
          {{ tag.name }}
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let tag of allTags" [value]="tag">
        <span class="tag-select" [ngStyle]="{ background: tag.color }">{{
          tag.name
        }}</span>
      </mat-option>
    </mat-select>
  </div>
</div>
