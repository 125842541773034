<div class="backdrop" [@animateOpacity] *ngIf="isOpen" (click)="close()"></div>

<aside [@slideInFromRight] *ngIf="isOpen">
  <button
    class="back-button"
    mat-icon-button
    color="white"
    aria-label="Sidebar schließen"
    (click)="close()"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>

  <ng-container *ngIf="!bigScreenSize">
    <a
      *ngFor="let button of navBarButtons"
      mat-flat-button
      [routerLink]="[button.link]"
      [routerLinkActive]="['active']"
    >
      {{ button.name }}
    </a>
    <mat-divider></mat-divider>
  </ng-container>

  <button *ngIf="showLoginButton" mat-flat-button (click)="login()">
    Login
  </button>
  <ng-container *ngIf="!showLoginButton">
    <button mat-flat-button (click)="logout()">Abmelden</button>
    <a
      *ngFor="let button of userButtons"
      mat-flat-button
      [routerLink]="[button.link]"
      [routerLinkActive]="['active']"
    >
      {{ button.name }}
    </a>
  </ng-container>

  <mat-divider class="push-down"></mat-divider>
  <a
    *ngFor="let button of sideBarButtons"
    mat-flat-button
    [routerLink]="[button.link]"
    [routerLinkActive]="['active']"
  >
    {{ button.name }}
  </a>
  <a mat-flat-button href="{{ supportURL }}" target="_blank">
    Support kontaktieren
  </a>
  <span class="copyright">
    <mat-icon>copyright</mat-icon>
    {{ today | date: "yyyy" }}
    Just Windsurfing Paderborn e.V.
  </span>
</aside>
