<div class="wrapper" *ngIf="showCookiePopup">
  <h3>
    Für ein angenehmes Nutzungsverhalten und das Bereitstellen aller Funktionen
    werden Cookies gespeichert. Mehr dazu finden Sie in unserer
    <a routerLink="privacyStatement">Datenschutzerklärung</a>.
  </h3>

  <button class="articleButton" (click)="denieCookie()">
    <span class="button-wrapper">
      Ablehnen
      <div class="arrow-right"></div>
    </span>
  </button>
  <button class="articleButton" (click)="acceptCookie()">
    <span class="button-wrapper">
      Zustimmen
      <div class="arrow-right"></div>
    </span>
  </button>
</div>
