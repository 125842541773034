<div #inputWatcher>
  <div *ngIf="!isEditing">
    <mat-icon class="editIcon" *ngIf="editMode" (click)="edit()">edit</mat-icon>
    <!-- ToDo - Check that this has no security implications -->
    <section
      [innerHTML]="value | platzhalter | unsafeHTML"
      (click)="edit()"
    ></section>
  </div>
  <div class="input-wrapper" *ngIf="isEditing">
    <mat-icon class="editIcon cancel" (click)="cancel()">close</mat-icon>
    <!-- <mat-quill
      format="html"
      placeholder="Artikel inhalt einfügen"
      [(ngModel)]="pendingValue"
    ></mat-quill> -->
  </div>
</div>
