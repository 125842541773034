<div
  *ngIf="articles && articles.length > 0"
  class="carousel-container"
  (mouseover)="disableAutorotate()"
  (mouseout)="enableAutorotate()"
>
  <a
    *ngFor="let article of articles; let i = index"
    [ngClass]="{ 'image-active': selectedIndex === i }"
    class="image-container fade"
    [ngStyle]="{
      backgroundImage:
        'url(' + picturePathConverter.transform(article.picturePath) + ')'
    }"
    [routerLink]="['/', 'article', article.id]"
  >
    <div class="banner">
      <h1>{{ article.title }}</h1>
      <h3>{{ article.subline }}</h3>
    </div>
  </a>

  <div class="dot-container">
    <div class="dot-container-background">
      <span
        *ngFor="let dot of articles; let i = index"
        class="dot"
        [ngClass]="{ active: selectedIndex === i }"
        (click)="selectImage(i)"
      >
      </span>
    </div>
  </div>
  <div class="btn-carousel btn-prev" (click)="onPrevClick()">
    <mat-icon>navigate_before</mat-icon>
  </div>
  <div class="btn-carousel btn-next" (click)="onNextClick()">
    <mat-icon>navigate_next</mat-icon>
  </div>
</div>

<app-loading *ngIf="!articles"></app-loading>
