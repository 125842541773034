import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/interfaces/article';
import { ArticleService } from 'src/app/services/article.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { BackgroundChangerService } from 'src/app/services/background-changer.service';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  providers: [BackgroundChangerService],
})
export class NewsComponent implements OnInit {
  isContentLoaded: Boolean = false;
  news!: Article[];

  constructor(
    private router: Router,
    private articleService: ArticleService,
    public user: UserService,
    private background: BackgroundChangerService,
    private message: MessagingService
  ) {}

  ngOnInit(): void {
    this.articleService.getArticles().subscribe(
      (res) => {
        this.news = res;
        this.isContentLoaded = true;
      },
      (err) => {
        this.message.defaultError();
      }
    );
  }

  createArticle() {
    this.router.navigate(['/article/new']);
  }
}
