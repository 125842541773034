import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ArticleComponent } from './pages/article/article.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { LoginPageComponent } from './pages/login/login.component';
import { NewsComponent } from './pages/news/news.component';
import { PageNotFoundComponent } from './pages/not-found/not-found.component';
import { PrivacyStatementComponent } from './pages/privacy-statement/privacy-statement.component';
import { AuthorGuard } from './services/_guards/author.guard';
import { PendingChangesGuard } from './services/_guards/pending-changes.guard';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'calendar', component: CalendarComponent },
  { path: 'news', component: NewsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'login', component: LoginPageComponent },
  {
    path: 'article/new',
    component: ArticleComponent,
    canActivate: [AuthorGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    component: ArticleComponent,
    matcher: (url) => {
      if (
        url.length === 2 && // check if there are two parts in the url
        url[0].path.match('article') && // check if the first part is article
        url[1].path.match(/^\d+$/) // check if the second is an integer
      ) {
        return {
          consumed: url,
          posParams: { id: new UrlSegment(url[1].toString(), {}) },
        };
      }
      return null;
    },
  },
  {
    component: ArticleComponent,
    matcher: (url) => {
      if (
        url.length === 3 && // check if there are two parts in the url
        url[0].path.match('article') && // check if the first part is article
        url[1].path.match(/^\d+$/) && // check if the second is an integer
        url[2].path.match('edit') // check if the last is edit
      ) {
        return {
          consumed: url,
          posParams: { id: new UrlSegment(url[1].toString(), {}) },
        };
      }
      return null;
    },
    canActivate: [AuthorGuard],
    canDeactivate: [PendingChangesGuard],
  },
  { path: 'article', redirectTo: '/news' },
  { path: 'imprint', component: ImprintComponent },
  { path: 'privacyStatement', component: PrivacyStatementComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' }, // redirect to `first-component`
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
