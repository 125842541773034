import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-cookieconsent',
  templateUrl: './cookieconsent.component.html',
  styleUrls: ['./cookieconsent.component.scss'],
})
export class CookieconsentComponent implements OnInit {
  showCookiePopup: boolean = true;

  constructor(private tokenSerivce: TokenStorageService) {}

  ngOnInit(): void {
    if (this.tokenSerivce.getCookie('acceptedCookieConsent') === 'true') {
      this.tokenSerivce.setUserAcceptedCookieConsent = true;
      this.showCookiePopup = false;
    }
  }

  acceptCookie(): void {
    this.tokenSerivce.setUserAcceptedCookieConsent = true;
    this.tokenSerivce.setCookie('acceptedCookieConsent', 'true', 7);
    this.showCookiePopup = false;
  }

  denieCookie(): void {
    this.showCookiePopup = false;
    this.tokenSerivce.setUserAcceptedCookieConsent = false;
  }
}
