import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../token-storage.service';
import { AUTH_KEY } from '../user.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private tokenStorage: TokenStorageService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.tokenStorage.getCookie(AUTH_KEY);

    if (token == null) {
      return next.handle(req);
    }

    const req1 = req.clone({
      headers: req.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`),
    });

    return next.handle(req1);
  }
}
