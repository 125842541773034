<main>
  <h1>Datenschutz&shy;erklärung</h1>

  <h3>1. Datenschutz auf einen Blick</h3>

  <p><strong>Allgemeine Hinweise</strong></p>
  <p>
    Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
    Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen.
    Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
    identifiziert werden können. Ausführliche Informationen zum Thema
    Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
    Datenschutzerklärung.
  </p>

  <p><strong>Datenerfassung auf unserer Website</strong></p>
  <p>
    <em>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</em>
  </p>
  <p>
    Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
    Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
  </p>

  <p><em>Wie erfassen wir Ihre Daten?</em></p>
  <p>
    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
    Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular
    eingeben.
  </p>
  <p>
    Andere Daten werden automatisch beim Besuch der Website durch unsere
    IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
    Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
    Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
    betreten.
  </p>

  <p><em>Wofür nutzen wir Ihre Daten?</em></p>
  <p>
    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
    Website zu gewährleisten. Andere Daten können zur Analyse Ihres
    Nutzerverhaltens verwendet werden.
  </p>

  <p><em>Welche Rechte haben Sie bezüglich Ihrer Daten?</em></p>
  <p>
    Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
    Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten.
    Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung
    dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
    Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
    Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der
    zuständigen Aufsichtsbehörde zu.
  </p>

  <p><strong>Analyse-Tools und Tools von Drittanbietern</strong></p>
  <p>
    Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet
    werden. Das geschieht vor allem mit Cookies und mit sogenannten
    Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel
    anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie
    können dieser Analyse widersprechen oder sie durch die Nichtbenutzung
    bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in
    der folgenden Datenschutzerklärung.
  </p>
  <p>
    Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten
    werden wir Sie in dieser Datenschutzerklärung informieren.
  </p>

  <h3>2. Allgemeine Hinweise und Pflichtinformationen</h3>

  <p><strong>Datenschutz</strong></p>
  <p>
    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr
    ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
    entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
    Datenschutzerklärung.
  </p>
  <p>
    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten
    erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
    identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert,
    welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
    und zu welchem Zweck das geschieht.
  </p>
  <p>
    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
    Kommunikation per E-Mail** Sicherheitslücken aufweisen kann. Ein lückenloser
    Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
  </p>

  <p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p>
  <p>
    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
    Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
    jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
    uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
    bleibt vom Widerruf unberührt.
  </p>

  <p><strong>Beschwerderecht bei der zuständigen Aufsichtsbehörde</strong></p>
  <p>
    Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
    Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
    Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
    Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen
    seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren
    Kontaktdaten können folgendem Link entnommen werden:
    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
  </p>

  <p><strong>Recht auf Datenübertragbarkeit</strong></p>
  <p>
    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
    Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen
    Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
    Sofern Sie die direkte Übertragung der Daten an einen anderen
    Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
    ist.
  </p>

  <p><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
  <p>
    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
    vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie
    an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine
    verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
    Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in
    Ihrer Browserzeile.
  </p>
  <p>
    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die
    Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
  </p>

  <p><strong>Auskunft, Sperrung, Löschung</strong></p>
  <p>
    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
    Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen
    Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und
    ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu
    sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
    jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
  </p>

  <p><strong>Widerspruch gegen Werbe-Mails</strong></p>
  <p>
    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
    Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung
    und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
    Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
    unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
  </p>

  <h3>3. Datenerfassung auf unserer Website</h3>

  <p><strong>Cookies</strong></p>
  <p>
    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten
    auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies
    dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu
    machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt
    werden und die Ihr Browser speichert.
  </p>
  <p>
    Die meisten der von uns verwendeten Cookies sind so genannte
    “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht.
    Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen.
    Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
    wiederzuerkennen.
  </p>
  <p>
    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
    informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von
    Cookies für bestimmte Fälle oder generell ausschließen sowie das
    automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei
    der Deaktivierung von Cookies kann die Funktionalität dieser Website
    eingeschränkt sein.
  </p>
  <p>
    Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder
    zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B.
    Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1
    lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes
    Interesse an der Speicherung von Cookies zur technisch fehlerfreien und
    optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B.
    Cookies zur Analyse Ihres Surfverhaltens** gespeichert werden, werden diese
    in dieser Datenschutzerklärung gesondert behandelt.
  </p>

  <p><strong>Server-Log-Dateien</strong></p>
  <p>
    Der Provider der Seiten erhebt und speichert automatisch Informationen in so
    genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
    übermittelt. Dies sind:
  </p>
  <pre><code>Browsertyp und Browserversion
verwendetes Betriebssystem
Referrer URL
Hostname des zugreifenden Rechners
Uhrzeit der Serveranfrage
IP-Adresse
</code></pre>
  <p>
    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
    vorgenommen.
  </p>
  <p>
    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die
    Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher
    Maßnahmen gestattet.
  </p>

  <p><strong>Kontaktformular</strong></p>
  <p>
    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
    Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
    Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
    Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
    Einwilligung weiter.
  </p>
  <p>
    Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit
    ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
    DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine
    formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
    Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
  </p>
  <p>
    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis
    Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
    widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
    abgeschlossener Bearbeitung Ihrer Anfrage**. Zwingende gesetzliche
    Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
  </p>

  <h3>4. Plugins und Tools</h3>

  <p><strong>YouTube</strong>*</p>
  <p>
    Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube.
    Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA
    94066, USA.
  </p>
  <p>
    Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten
    besuchen, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei
    wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
  </p>
  <p>
    Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube,
    Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können
    Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
  </p>
  <p>
    Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung
    unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von
    Art. 6 Abs. 1 lit. f DSGVO dar.
  </p>
  <p>
    Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
    Datenschutzerklärung von YouTube unter:
    https://www.google.de/intl/de/policies/privacy.
  </p>

  <p><strong>Google Web Fonts</strong></p>
  <p>
    Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte
    Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite
    lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte
    und Schriftarten korrekt anzuzeigen.
  </p>
  <p>
    Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
    Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
    dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von
    Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden
    Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse
    im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
  </p>
  <p>
    Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von
    Ihrem Computer genutzt.
  </p>
  <p>
    Weitere Informationen zu Google Web Fonts finden Sie unter
    https://developers.google.com/fonts/faq und in der Datenschutzerklärung von
    Google: https://www.google.com/policies/privacy/.
  </p>

  <p><strong>Google Maps</strong></p>
  <p>
    Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist
    die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
  </p>
  <p>
    Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse
    zu speichern. Diese Informationen werden in der Regel an einen Server von
    Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite
    hat keinen Einfluss auf diese Datenübertragung.
  </p>
  <p>
    Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
    Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der
    von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
    Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
  </p>
  <p>
    Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
    Datenschutzerklärung von Google:
    https://www.google.de/intl/de/policies/privacy/.
  </p>
</main>
