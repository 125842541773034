import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Article, ArticleDetails, ArticleUpdate } from '../interfaces/article';

const API_URl = environment.apiBaseUrl + 'article/';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(private http: HttpClient) {}

  getArticles(): Observable<Article[]> {
    return this.http.get<Article[]>(API_URl + 'all');
  }

  getTopArticles(): Observable<Article[]> {
    return this.http.get<Article[]>(API_URl + 'top');
  }

  getArticleDetails(id: Number): Observable<ArticleDetails> {
    return this.http.get<ArticleDetails>(API_URl + id);
  }

  saveArticle(article: ArticleUpdate): Observable<ArticleDetails> {
    return this.http.put<ArticleDetails>(API_URl, article);
  }

  saveArticlePicture(id: Number, picture: File): Observable<string> {
    const formData = new FormData();
    formData.append('image', picture);
    return this.http.put(API_URl + id + '/image', formData, {
      responseType: 'text',
    });
  }
}
