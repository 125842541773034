import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const API_URl = environment.apiBaseUrl + 'email/';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient) {}

  public sendMessage(
    name: string,
    email: string,
    phone: string,
    message: string
  ) {
    return this.http.post(
      API_URl + 'contact',
      {
        name: name,
        email: email,
        phone: phone,
        text: message,
      },
      {
        responseType: 'text',
      }
    );
  }
}
