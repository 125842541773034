import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleDetails } from 'src/app/interfaces/article';
import { ArticleService } from 'src/app/services/article.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import {
  BackgroundChangerService,
  BackgroundGetterService,
} from 'src/app/services/background-changer.service';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { Observable, of } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/services/_guards/pending-changes.guard';
import { PictureUploadComponent } from './picture-upload/picture-upload.component';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  providers: [BackgroundChangerService],
})
export class ArticleComponent implements OnInit, ComponentCanDeactivate {
  isContentLoaded = false; // True if content was fetched from database
  isArticleEdited = false; // If the original article was edited in the database
  articleWasEdited = false; // If the currently displayed article was edited by the user
  editMode = false;
  isInEdit = false;
  isSaving: boolean = false;

  article!: ArticleDetails;

  file: any = null;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // Negate because if false there is a warning and if true there is not
    return !this.articleWasEdited;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private articleService: ArticleService,
    public user: UserService,
    private dialog: MatDialog,
    private background: BackgroundChangerService,
    private backgroundSetter: BackgroundGetterService,
    private message: MessagingService,
  ) {}

  ngOnInit(): void {
    if (this.router.url == '/article/new') {
      this.article = {
        title: '',
        subline: '',
        tags: undefined,
        text: '',
        picturePath: '',
        creationDate: new Date(),
        lastEdit: new Date(),
        id: 0,
        author: {},
      };
      this.isContentLoaded = true;
      this.editMode = true;
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.loadArticle(params['id']);
      });
      if (this.router.url.includes('edit')) {
        this.editMode = true;
      }
    }
  }

  // Fetch article from database
  loadArticle(id: Number) {
    this.articleService.getArticleDetails(id).subscribe(
      (res) => {
        this.article = res;
        this.backgroundSetter.setImagePath(this.article.picturePath);
        this.background.setBackground();
        this.isArticleEdited =
          this.article.creationDate != this.article.lastEdit;
        this.isContentLoaded = true;
      },
      (err) => {
        this.message.defaultError();
      },
    );
  }

  editArticle() {
    this.router.navigate([this.router.url, 'edit']);
  }

  updateArticle(position: string, value: any) {
    this.articleWasEdited = true;
    switch (position) {
      case 'title':
        this.article.title = value;
        break;
      case 'subline':
        this.article.subline = value;
        break;
      case 'tags':
        this.article.tags = value;
        break;
      case 'content':
        this.article.text = value;
        break;
    }
  }

  saveArticle() {
    if (!this.articleWasEdited) {
      this.goBack();
      return;
    }
    let error = [];
    if (this.article.title == '') error.push('Überschrift');
    if (this.article.subline == '') error.push('Unterüberschrift');
    if (this.article.text == '') error.push('Inhalt');
    if (this.article.picturePath == '' && this.file == null) error.push('Bild');

    if (error.length > 0) {
      this.message.info('Bitte ' + error + ' eingeben!');
      return;
    }

    this.isSaving = true;

    this.articleService.saveArticle(this.article).subscribe(
      (res) => {
        this.article = res;
        this.saveImage().subscribe(
          (res) => {
            this.articleWasEdited = false;
            this.router.navigate(['article', this.article.id]);
            this.message.success('Artikel wurde gespeichert.');
          },
          (err) => {
            this.message.error(
              'Bild konnte nicht gespeichert werden! Versuche es später erneut.',
            );
          },
        );
        this.isSaving = false;
      },
      (err) => {
        this.message.error(
          'Artikel konnte nicht gespeichert werden! Versuche es später erneut.',
        );
        this.isSaving = false;
      },
    );
  }

  // Open a dialog to show image in full screen
  viewImage(event: Event) {
    event.stopPropagation();
    this.dialog.open(ImageViewerComponent, {
      data: { image: this.article.picturePath },
    });
  }

  updateImage(event: Event) {
    event.stopPropagation();
    const dialog = this.dialog.open(PictureUploadComponent, {
      data: { file: this.file },
    });

    dialog.afterClosed().subscribe((result) => {
      // Check if uploaded file was an image
      if (
        result.file.type.match(/image\/(gif|jpeg|png|svg\+xml|webp)/) == null
      ) {
        this.message.error('Dateiformat nicht unterstützt!');
        return;
      }
      this.articleWasEdited = true;
      this.file = result.file;
      // Display the new image
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.article.picturePath =
          reader.result?.toString() === undefined
            ? ''
            : reader.result?.toString();
      };
    });
  }

  saveImage(): Observable<string> {
    if (this.file != null)
      return this.articleService.saveArticlePicture(this.article.id, this.file);
    return of('null');
  }

  goBack() {
    this.location.back();
  }
}
