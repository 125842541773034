<main *ngIf="isContentLoaded">
  <header>
    <div class="button-wrapper">
      <button mat-icon-button (click)="goBack()" class="backbutton">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div
      class="image"
      (click)="viewImage($event)"
      style="background-image: url({{
        article.picturePath | picturePathConverter
      }})"
    >
      <mat-icon
        class="editIcon"
        *ngIf="user.isAuthor() && editMode"
        (click)="updateImage($event)"
        >edit</mat-icon
      >
    </div>

    <app-article-headline
      [value]="article.title"
      [editMode]="editMode"
      (isInEdit)="isInEdit = $event"
      (valueChangeEvents)="updateArticle('title', $event)"
    ></app-article-headline>
  </header>

  <article>
    <button
      *ngIf="user.isAuthor() && !editMode"
      mat-flat-button
      color="accent"
      class="editbutton"
      aria-label="Artikel bearbeiten"
      (click)="editArticle()"
    >
      <mat-icon>edit</mat-icon>
      Artikel bearbeiten
    </button>
    <button
      *ngIf="user.isAuthor() && editMode"
      mat-flat-button
      color="primary"
      class="editbutton save"
      aria-label="Speichern"
      (click)="saveArticle()"
      [disabled]="isInEdit"
    >
      <span *ngIf="!articleWasEdited">Abbrechen</span>
      <span *ngIf="articleWasEdited && !isSaving">Änderungen speichern</span>
      <app-loading *ngIf="articleWasEdited && isSaving"></app-loading>
    </button>

    <app-article-subline
      [value]="article.subline"
      [editMode]="editMode"
      (isInEdit)="isInEdit = $event"
      (valueChangeEvents)="updateArticle('subline', $event)"
    ></app-article-subline>

    <div class="infos" *ngIf="!isArticleEdited">
      Geschrieben am {{ article.lastEdit | date: "dd.MM.y" }}
      <span
        *ngIf="article.author.id !== null && article.author.id !== undefined"
      >
        von {{ article.author.firstname }} {{ article.author.surename }}
      </span>
    </div>
    <div class="infos" *ngIf="isArticleEdited">
      <span
        *ngIf="article.author.id !== null && article.author.id !== undefined"
      >
        Geschrieben von {{ article.author.firstname }}
        {{ article.author.surename }} |</span
      >
      Aktualisiert am
      {{ article.lastEdit | date: "dd.MM.y" }}
    </div>

    <app-article-tags
      [tags]="article.tags"
      [editMode]="editMode"
      (isInEdit)="isInEdit = $event"
      (valueChangeEvents)="updateArticle('tags', $event)"
    ></app-article-tags>

    <app-article-content
      [value]="article.text"
      [editMode]="editMode"
      (isInEdit)="isInEdit = $event"
      (valueChangeEvents)="updateArticle('content', $event)"
    ></app-article-content>
  </article>
</main>

<main *ngIf="!isContentLoaded">
  <header><app-loading class="loading"></app-loading></header>
  <article><app-loading class="loading"></app-loading></article>
</main>
