import { Component } from '@angular/core';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  imprint = ImprintData;

  constructor() {}
}

export const ImprintData = {
  street: 'Hauptstraße 48',
  city: '33181 Bad Wünnenberg',
  reg_nr: 'VR 3275',
  reg_place: 'Paderborn',
  chairman: 'Benjamin Meerkötter',
  second_chairman: 'Matthias Raimann',
  management: 'Renate Günther',
  treasurer: 'Johannes Sprenger',
  email: 'surfen@jws-pb.de',
  phone: '0173 8170947',
  editor: 'Richard Sprenger',
  editor_street: 'Hauptstraße 48',
  editor_city: '33181 Bad Wünnenberg',
};
