import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Role, User } from '../interfaces/user';
import { TokenStorageService } from './token-storage.service';
import { Buffer } from 'buffer';
import { MessagingService } from './messaging.service';

const API_URl = environment.apiBaseUrl + 'user/';
const USER_KEY = 'user';
export const AUTH_KEY = 'auth-token';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: User | null = null;
  private _isLoggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService,
    private message: MessagingService
  ) {
    const user = window.sessionStorage.getItem(USER_KEY);
    const userCookie = this.tokenStorage.getCookie(AUTH_KEY);

    if (user) {
      // If the User is stored in the session than just use that information
      this.user = JSON.parse(user);
      this.setIsLoggedIn = true;
    } else if (
      userCookie !== null &&
      userCookie !== '' &&
      !this.tokenExpired(userCookie)
    ) {
      // If no user is stored in the session storage bus auth-token is set as a cookie use token and fetch user details
      this.initUser();
    }
  }

  initUser(): Promise<boolean> {
    const loaded = new Promise<boolean>((resolve) => {
      this.getUserData().subscribe(
        (res) => {
          this.user = res;
          this.getRolesData().subscribe(
            (roles) => {
              if (this.user) this.user.roles = roles;
              window.sessionStorage.setItem(
                USER_KEY,
                JSON.stringify(this.user)
              ); // Write to sessionStorage in order to survive page refresh
              this.setIsLoggedIn = true;
              resolve(true);
            },
            (err) => {
              this.message.defaultError();
              resolve(false);
            }
          );
        },
        (err) => {
          this.message.defaultError();
          resolve(false);
        }
      );
    });
    return loaded;
  }

  public set setIsLoggedIn(status: boolean) {
    this._isLoggedIn.next(status);
  }

  public get isLoggedIn(): Observable<boolean> {
    return this._isLoggedIn.asObservable();
  }

  public signOut(): void {
    this.user = null;
    this.setIsLoggedIn = false;
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.removeItem(AUTH_KEY);
    this.tokenStorage.deleteCookie(AUTH_KEY);
    window.location.reload();
  }

  public getUser(): User | null {
    return this.user;
  }

  public getRoles(): Role[] {
    return this.user?.roles ?? [];
  }

  public isAuthor(): boolean {
    return this.getRoles().filter((role) => role.id === 2).length > 0;
  }

  public isTrainer(): boolean {
    return this.getRoles().filter((role) => role.id === 3).length > 0;
  }

  public isVorstand(): boolean {
    return this.getRoles().filter((role) => role.id === 1).length > 0;
  }

  private getUserData(): Observable<User> {
    if (this.user && this.user.id) return of(this.user);
    return this.http.get<User>(API_URl);
  }

  private getRolesData(): Observable<Role[]> {
    if (!this.user) return of([]);
    if (this.user.roles) return of(this.user.roles);
    return this.http.get<Role[]>(API_URl + this.user.id + '/roles');
  }

  public tokenExpired(token: string): boolean {
    const jsonString = Buffer.from(token.split('.')[1], 'base64');
    const expiry = JSON.parse(jsonString.toString('binary')).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
