import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ArticleBaseComponent } from '../article-base/article-base.component';

@Component({
  selector: 'app-article-subline',
  templateUrl: './article-subline.component.html',
  styleUrls: ['./article-subline.component.scss'],
})
export class ArticleSublineComponent
  extends ArticleBaseComponent
  implements AfterViewInit
{
  @ViewChild('inputWatcher') _elem!: ElementRef;

  ngAfterViewInit() {
    this.elem = this._elem;
  }
}
