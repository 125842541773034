<h1>
  Artikel Bild wählen
  <mat-icon
    class="infoIcon"
    matTooltip="Für eine optimale Darstellung des Bildes auf allen Seiten ein 16:9 Format wählen. Des Weiteren sollte der Hauptfokus des Bildes ca. in der Mitte des Bildbereiches liegen."
    matTooltipHideDelay="1000"
    matTooltipClass="info-tooltip"
  >
    info
  </mat-icon>
</h1>

<div class="container">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    (change)="onFileChanged($event)"
  />
  <mat-icon class="fileUploadIcon">file_upload</mat-icon>
  <h3>Drag & Drop</h3>
  <h3>oder</h3>
  <label for="fileDropRef">Bild auswählen</label>
</div>
