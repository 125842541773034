import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { PicturePathConverterPipe } from './_pipe/picture-path-converter.pipe';

const ELEMENT = 'app-main-container';

// this service handels the image randomization once per sesstion
@Injectable({
  providedIn: 'root',
})
export class BackgroundGetterService {
  picturePathPipe = new PicturePathConverterPipe();
  img: string = '';

  constructor() {
    this.randomImage();
  }

  // ToDo: get Background from Server
  getImagePaths(): Observable<string[]> {
    const IMAGES = [
      '/assets/images/backgrounds/activities.jpg',
      '/assets/images/backgrounds/contact.jpg',
      '/assets/images/backgrounds/news.jpg',
    ];
    return of(IMAGES);
  }

  setImagePath(path: string) {
    this.img = this.picturePathPipe.transform(path);
  }

  randomImage() {
    this.getImagePaths().subscribe((images) => {
      // choose random list entry:
      this.img = images[Math.floor(Math.random() * images.length)];
    });
  }
}

// this injectable will be constructed and deconstructed every time you request it on a component.
// But you need to privide it to it by using `providers: [BackgroundChangerService]` inside the `@Component()`
@Injectable({
  providedIn: null,
})
export class BackgroundChangerService implements OnDestroy {
  doc: HTMLElement | null;

  constructor(private background: BackgroundGetterService) {
    this.doc = document.getElementById(ELEMENT);
    this.setBackground();
  }

  setBackground() {
    if (!this.doc) throwError(() => new Error('Background Element not found'));
    else this.doc.style.backgroundImage = "url(' " + this.background.img + "')";
  }

  ngOnDestroy(): void {
    if (this.doc) this.doc.style.removeProperty('background-image');
  }
}
