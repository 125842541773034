import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';
import { AUTH_KEY, UserService } from './user.service';
import { MessagingService } from './messaging.service';

const AUTH_API = environment.apiBaseUrl + 'login';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService,
    private user: UserService,
    private message: MessagingService
  ) {}

  // ToDo: this needs to return a bool indicating its success
  public async login(username: string, password: string): Promise<boolean> {
    const loaded = new Promise<boolean>((resolve) => {
      this.http
        .post<any>(
          AUTH_API,
          new HttpParams()
            .set('username', username)
            .set('password', password)
            .toString(),
          httpOptions
        )
        .subscribe(
          (res: { access_token: string; }) => {
            this.tokenStorage.setCookie(AUTH_KEY, res.access_token, 7);
            this.user.initUser().then(() => {
              resolve(true);
            });
          },
          () => {
            this.message.defaultError();
            resolve(false);
          }
        );
    });
    return loaded;
  }

  // ToDo: Add sign Up Method
}
