<main>
  <aside>
    <h1>UNSERE</h1>
    <h3>NEUIGKEITEN</h3>
    <div class="text-box">
      <p>
        Immer über das aktuellste Geschehen informiert. <br />
        Durch die neusten Informationen aus dem JWS haben Sie alle Neuigkeiten
        auf einen Blick.
      </p>
      <div class="line"></div>
    </div>
    <button
      *ngIf="user.isAuthor()"
      aria-label="Neuen Artikel erstellen"
      (click)="createArticle()"
      class="articleButton"
    >
      <span>Neuer Artikel</span>
      <div class="arrow-right"></div>
    </button>
  </aside>

  <div class="container">
    <div class="list">
      <div *ngIf="isContentLoaded">
        <a
          class="section"
          *ngFor="let article of news"
          [routerLink]="['/', 'article', article.id]"
        >
          <span class="date"
            >{{ article.creationDate | date: "dd.MM.y" }}
          </span>
          <div class="tagWrapper">
            <span
              *ngFor="let tag of article.tags"
              class="tag"
              [ngStyle]="{ background: tag.color }"
            >
              {{ tag.name }}
            </span>
          </div>

          <h1>{{ article.title }}</h1>
          <h2>{{ article.subline }}</h2>
        </a>
      </div>
      <app-loading *ngIf="!isContentLoaded"></app-loading>
    </div>
  </div>
</main>
