<main>
  <svg
    width="400px"
    version="1.1"
    viewBox="0 0 637.5 340.6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(2160 -599.5)">
      <g transform="translate(-2325 186.8)">
        <path
          d="m561.3 538.9c-4.83 0.4976-6.406 23.65-3.518 51.69 0.4212 4.088 0.9208 8.033 1.471 11.83 5.674-0.474 11.35-0.9308 17.02-1.375-0.2366-3.941-0.559-8.033-0.9941-12.26-2.888-28.03-9.152-50.38-13.98-49.88z"
          style="fill-rule: evenodd"
        />
        <path
          d="m576.7 614.7c-0.9011 0.0774-1.802 0.1582-2.703 0.2363 0.1393 6.957-2.965 13.38-7.469 18.79 1.78 4.427 3.582 6.905 5.221 6.736 3.205-0.3302 4.966-10.67 4.951-25.76z"
          style="fill-rule: evenodd"
        />
        <path
          d="m802.1 604.9c-0.0652 0.6429-7.958 0.3706-22.27-0.0984-22.44-0.7381-53.84-1.52-99.25 0.444-49.01 2.121-91.88 6.197-155.2 12-8.278 0.7612-34.65 3.152-42.93 3.913-56.5 5.149-102.2 9.539-149 15.33-100.6 12.43-168.5 26.37-168.9 24.5-0.3969-1.874 66.92-19.12 167.7-33.26 46.11-6.471 93.62-11.45 149.3-16.53 14.48-1.32 28.99-2.599 42.95-3.824 61.76-5.43 108.1-9.092 155.8-9.969 45.07-0.8274 77.99 1.298 99.75 3.739 14.3 1.605 22.07 3.108 22 3.751z"
          style="fill-rule: evenodd"
        />
        <path
          d="m670.6 609.3c0.4235 0.1472 0.2305 3.287-2.186 8.087-2.375 4.717-7.067 11.07-15 15.56-4.924 2.758-10.36 4.492-16.37 5.85-0.8656 0.1954-1.725 0.3793-2.574 0.5542h-1e-5c-11.99 2.512-22.58 3.617-30.53 3.928-7.835 0.306-12.64-0.168-12.68-0.8343-0.0384-0.721 4.691-1.512 12.32-2.738 7.998-1.285 17.82-2.86 29.8-5.451h1e-5c0.829-0.1823 1.666-0.3682 2.501-0.5589 5.825-1.331 10.77-2.74 15.23-5.025 7.122-3.606 11.87-8.87 14.76-12.82 2.924-3.998 4.124-6.754 4.723-6.546z"
          style="fill-rule: evenodd"
        />
        <path
          d="m568.4 615.7c0.1524 0.0581-0.2868 9.291-11.02 18.87h-1e-5c-7.074 6.25-15.96 10.12-27.37 13.62-0.885 0.2756-1.777 0.5488-2.676 0.8194-14.57 4.388-27.59 7.09-37.5 8.578-9.751 1.464-15.86 1.667-15.97 0.9848-0.1173-0.7397 5.78-2.232 15.25-4.603 9.965-2.495 22.02-5.529 36.72-9.956 0.8871-0.2671 1.768-0.5346 2.643-0.8024 11.34-3.528 19.39-6.506 26.25-11.84v-1e-5c4.909-3.77 8.146-7.978 10.19-10.95 2.071-3.011 3.052-4.891 3.493-4.723z"
          style="fill-rule: evenodd"
        />
        <path
          d="m484.5 623.8c0.3402 0.2306-2.541 8.028-12.93 16.14-6.753 5.248-14.55 8.967-24.8 13.01-2.922 1.16-5.93 2.319-9.016 3.47-29.91 11.16-55.13 16.86-55.52 15.48-0.3934-1.382 24.09-9.327 53.7-20.37 3.069-1.145 6.054-2.268 8.977-3.378 10.36-3.958 17.54-6.909 24.33-11.44 10.17-6.747 14.35-13.53 15.26-12.91z"
          style="fill-rule: evenodd"
        />
        <path
          d="m400.6 630.8c0.5581 0.4294-2.862 7.586-12.29 15.07-5.185 4.103-11.24 7.551-18.61 10.95-1.595 0.7353-3.18 1.436-4.726 2.104-18.25 7.917-33.02 12.23-33.49 11-0.4779-1.253 13.46-7.604 31.39-15.74h1e-5c1.507-0.6863 3.088-1.405 4.636-2.119 7.324-3.38 12.93-6.254 18.17-9.782 9.255-6.206 14.03-12.17 14.91-11.49z"
          style="fill-rule: evenodd"
        />
        <path
          d="m315.9 637.2c0.5119 0.3022-1.392 7.332-9.947 14.45-4.86 4.01-10.54 6.958-17.56 9.937-1.324 0.562-2.646 1.106-3.92 1.627v1e-5c-16.82 6.938-30.04 12.23-30.63 11.01-0.5768-1.193 11.72-8.496 28.61-15.79 1.289-0.5611 2.565-1.104 3.887-1.667 7.041-3.001 12.14-5.289 16.94-8.629 8.154-5.618 11.63-11.52 12.62-10.94z"
          style="fill-rule: evenodd"
        />
        <path
          d="m238 654.8c0.2488 0.6953-2.136 2.171-5.969 4.32-4.212 2.362-8.753 4.794-14.78 8.468-0.3846 0.2347-0.7659 0.4688-1.144 0.7023-10.7 6.609-18.77 12.91-19.72 11.9-0.9236-0.9872 5.672-9.16 16.95-16.31 0.392-0.2486 0.789-0.4955 1.191-0.7406 5.976-3.646 11.65-6.05 16.04-7.407 4.342-1.339 7.182-1.586 7.417-0.9273z"
          style="fill-rule: evenodd"
        />
        <path
          d="m627.2 593c-0.6307-0.4477 0.7041-2.076 0.1684-5.34-0.2116-1.31-0.8039-2.854-1.957-4.094-0.8978-0.9732-2.193-1.8-3.653-2.118-1.253-0.2792-2.709-0.1943-3.983 0.3437-0.9414 0.3791-1.847 1.048-2.665 1.903-0.7712 0.8079-1.425 1.823-2.004 2.984-1.125 2.213-1.67 4.792-2.448 7.95-1.505 6.34-3.279 13.07-5.342 20.08-9.706 32.97-16.24 43.12-18.06 42.39-1.827-0.738 1.64-11.96 11.19-44.41 2.057-6.987 3.917-13.49 5.679-19.75 0.8444-3.107 1.766-6.206 3.458-9.23 0.9051-1.597 2.043-3.057 3.402-4.299 1.453-1.33 3.165-2.313 5.001-2.878h1e-5c2.543-0.7606 5.088-0.5853 7.242 0.1937 2.493 0.9093 4.346 2.571 5.511 4.365 1.475 2.283 1.815 4.684 1.641 6.539-0.4429 4.625-3.091 5.438-3.179 5.375z"
          style="fill-rule: evenodd"
        />
        <circle cx="558" cy="512.4" r="21" />
        <path
          d="m550.8 546.8c1.179 0.9342-1.472 6.56-7.034 14.47-4.618 6.575-11.37 14.86-19.79 22.87-3.992 3.798-7.985 7.18-11.92 10.15-14.5 10.92-26.72 15.09-27.72 13.32-1.073-1.884 8.997-9.305 21.77-20.62 3.545-3.135 7.13-6.426 10.98-10.09 8.059-7.675 15.23-15.03 20.95-20.53 6.843-6.588 11.59-10.5 12.76-9.57z"
          style="fill-rule: evenodd"
        />
        <path
          d="m570.6 542.5c1.738-1.444 7.934 3.216 15.46 10.12 1.851 1.677 3.465 3.209 5.402 5.006 3.651 3.388 6.388 5.763 9.478 8.024 5.246 3.906 9.646 6.152 9.257 7.959-0.3383 1.571-5.708 2.676-13.37-0.526-4.108-1.748-8.212-4.463-12.16-8.092-2.069-1.901-3.898-3.868-5.484-5.748v-1e-5c-6.966-8.154-10.28-15.33-8.581-16.75z"
          style="fill-rule: evenodd"
        />
        <path
          d="m565.7 643.7c2.698 0.565-0.348 25.24-4.807 55.29-1.132 7.63-2.285 15.07-3.366 21.64-3.179 19.32-6.044 32.85-8.09 32.67-2.047-0.1809-2.696-14.1-1.019-33.8 0.5771-6.778 1.427-14.18 2.584-21.97 4.527-30.51 12-54.39 14.7-53.83z"
          style="fill-rule: evenodd"
        />
        <path
          d="m578.9 643.7c2.698-0.5652 10.17 23.32 14.7 53.83 1.156 7.792 2.006 15.2 2.584 21.97 1.677 19.7 1.028 33.62-1.019 33.8-2.047 0.1809-4.911-13.35-8.09-32.67-1.081-6.568-2.233-14.01-3.366-21.64-4.459-30.05-7.505-54.73-4.807-55.29z"
          style="fill-rule: evenodd"
        />
      </g>
      <path
        d="m-1732 601.7c1.104-0.8072 9.12-3.774 18.78-1.066 1.887 0.5176 4.019 1.479 6.024 3.221 1.464 1.293 2.648 3.111 3.222 5.405 0.4317 2.107 0.2845 4.191-0.3771 6.044-0.7043 2.008-1.762 3.763-3.026 5.214-2.966 3.426-6.828 5.887-11.03 7.332-2.133 0.8459-4.194 1.971-5.582 3.415-0.4548 0.4734-0.8318 0.975-1.119 1.507-0.9385 1.62-1.279 3.627-0.6876 4.901 0.4526 2.017 3.21 3.622 5.573 4.657 4.644 1.844 10.85 1.817 13.4 1.974 3.104 0.192 4.565 0.6071 3.412 1.769-1.01 1.016-8.593 5.175-18.73 3.2-3.947-0.6992-8.533-2.994-11.33-8.091-1.86-4.096-1.722-8.861 0.548-12.87 2.623-4.495 6.816-7.616 11.46-9.134 1.408-0.5181 2.777-1.128 4.022-1.852 1.342-0.7803 2.497-1.667 3.419-2.681 0.7343-0.8151 1.312-1.561 1.597-2.278 0.2305-0.6038 0.3916-1.136 0.3333-1.481 0.043-0.3637-0.2422-0.8954-0.6099-1.397-0.6009-0.8109-1.765-1.484-2.981-2.104-4.327-2.13-10.89-2.955-13.21-3.429-3.078-0.6294-4.388-1.304-3.091-2.253z"
      />
      <circle
        cx="-1723"
        cy="663"
        r="5.568"
        style="fill-rule: evenodd; stroke-width: 0"
      />
    </g>
  </svg>
  <h3>404: Seite nicht gefunden!</h3>
</main>
