<main>
  <section class="header-container">
    <h1>JUST WINDSURFING PADERBORN</h1>
    <h3>Der Paderborner Surfverein</h3>
  </section>

  <section class="news-container">
    <h3 class="header">NEUIGKEITEN</h3>
    <div class="content-container">
      <app-carousel></app-carousel>
    </div>
  </section>

  <section
    class="interest-container"
    [ngStyle]="{
      'align-self': showCookieRelatedItems === false ? 'flex-start' : 'flex-end'
    }"
  >
    <h1>INTERESSE</h1>
    <h4>am Windsurfen?</h4>
    <p>
      Dann schreib uns eine <a routerLink="/contact">NACHRICHT</a> !<br />
      An unserem Gelände am Lippesee kannst du das Windsurfen ausprobieren, oder
      deine Erfahrungen durch die Vereinstrainings ausbauen.
    </p>
    <div class="line"></div>
  </section>

  <section class="windfinder-container" *ngIf="showCookieRelatedItems">
    <h3 class="header">WINDVORHERSAGE</h3>
    <div class="content-container">
      <iframe
        id="wf-forecast-lippesee_paderborn"
        src="https://www.windfinder.com/widget/forecast/lippesee_paderborn?days=3&columns=3&unit_wind=kts&unit_temperature=c&unit_wave=m&unit_rain=mm&unit_pressure=hPa&label_wind=kts&show_wind=1&show_temperature=1&show_waves=0&show_rain=0&show_clouds=1&show_pressure=0&show_day=1&version=1,"
        scrolling="no"
        frameborder="0"
      ></iframe>
    </div>
  </section>

  <section class="maps-container" *ngIf="showCookieRelatedItems">
    <h3 class="header">ANFAHRT</h3>
    <div class="content-container">
      <app-approch-map></app-approch-map>
    </div>
  </section>
</main>
