import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ArticleBaseComponent } from '../article-base/article-base.component';

@Component({
  selector: 'app-article-headline',
  templateUrl: './article-headline.component.html',
  styleUrls: ['./article-headline.component.scss'],
})
export class ArticleHeadlineComponent
  extends ArticleBaseComponent
  implements AfterViewInit
{
  @ViewChild('inputWatcher') _elem!: ElementRef;

  ngAfterViewInit() {
    this.elem = this._elem;
  }
}
