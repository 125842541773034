<div class="loading-wrapper">
  <button class="waveBtn">
    <svg width="40" height="30" viewBox="5 0 80 60" class="waveImg">
      <path
        class="wave"
        fill="none"
        stroke="#0294fd"
        stroke-width="3"
        stroke-linecap="round"
        d="M 0 37.5 c 7.684299348848887 0 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15"
      />
    </svg>
  </button>
  <p class="label">Läd ...</p>
</div>
