import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'platzhalter',
})
export class PlatzhalterPipe implements PipeTransform {
  transform(value: string): string {
    if (value == null || value === '') return 'Platzhalter';
    return value;
  }
}
