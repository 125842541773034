import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatDialog as MatDialog,
  MatDialogRef as MatDialogRef,
  MAT_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-picture-upload',
  templateUrl: './picture-upload.component.html',
  styleUrls: ['./picture-upload.component.scss'],
  // Need to remove view encapsulation so that the custom tooltip style defined in
  // `tooltip-custom-class-example.css` will not be scoped to this component's view.
  encapsulation: ViewEncapsulation.None,
})
export class PictureUploadComponent {
  constructor(
    public dialogRef: MatDialogRef<PictureUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { file: any }
  ) {}

  onFileChanged(event: any) {
    this.data.file = event.target.files[0];
    if (this.data.file !== undefined) this.dialogRef.close(this.data);
  }
}
