import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  signinForm: FormGroup;
  error: string = '';
  isSigningIn: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    public user: UserService,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private message: MessagingService,
  ) {
    this.signinForm = this.fb.group({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  submit(formDirective: FormGroupDirective) {
    if (!this.signinForm.valid) {
      return;
    }

    this.isSigningIn = true;

    this.authService
      .login(this.signinForm.value.username, this.signinForm.value.password)
      .then((res) => {
        this.isSigningIn = false;
        if (!res) {
          this.message.error('Benutzername oder Passwort falsch!');
          this.signinForm.reset();
          formDirective.resetForm();
        } else {
          this.message.success('Erfolgreich angemeldet!');
          this.dialogRef.close();
        }
      });
  }
}
