import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BackgroundChangerService } from 'src/app/services/background-changer.service';
import { ContactService } from 'src/app/services/contact.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { ImprintData } from '../imprint/imprint.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [BackgroundChangerService],
})
export class ContactComponent {
  imprintData = ImprintData;

  contactForm = this.formBuilder.group({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.email,
    ]),
    phone: new FormControl('', [
      Validators.minLength(6),
      Validators.pattern('[- +()0-9]+'),
    ]),
    message: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
    ]),
  });

  isSending: boolean = false;

  constructor(
    private background: BackgroundChangerService,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private message: MessagingService,
    private dialog: MatDialog
  ) {}

  onSubmit(formDirective: FormGroupDirective): void {
    if (!this.contactForm.invalid) {
      this.isSending = true;
      if (
        this.contactForm.controls.name.value === null ||
        this.contactForm.controls.email.value === null ||
        this.contactForm.controls.phone.value === null ||
        this.contactForm.controls.message.value === null
      ) {
        this.displaySendingErrorMessage();
        return;
      }
      this.contactService
        .sendMessage(
          this.contactForm.controls.name.value,
          this.contactForm.controls.email.value,
          this.contactForm.controls.phone.value,
          this.contactForm.controls.message.value
        )
        .subscribe(
          (res) => {
            this.contactForm.reset();
            formDirective.resetForm();
            this.message.success(
              'Nachricht wurde erfolgreich gesendet und wird schnellstmöglich bearbeitet.'
            );
            this.isSending = false;
          },
          (err) => {
            this.displaySendingErrorMessage();
          }
        );
    }
  }

  displaySendingErrorMessage(): void {
    this.message.error(
      'Beim Senden der Nachricht ist ein Fehler aufgetreten! Bitte Versuche es später erneut.'
    );
    this.isSending = false;
  }

  getNameErrorMessage(): string {
    if (this.contactForm.controls.name.hasError('minlength'))
      return 'Bitte mindestens drei Buchstaben eingeben';
    return 'Bitte einen Namen eingeben';
  }

  getEmailErrorMessage(): string {
    if (this.contactForm.controls.email.hasError('required'))
      return 'Bitte eine E-Mail Adresse eingeben';
    return 'Bitte eine gültige E-Mail eingeben';
  }

  getPhoneErrorMessage(): string {
    return 'Bitte eine gültige Telefonnummer eingeben';
  }

  getMessageErrorMessage(): string {
    if (this.contactForm.controls.message.hasError('minlength'))
      return 'Bitte eine Nachricht länger als 10 Zeichen eingeben';
    return 'Bitte eine Nachricht eingeben';
  }
}
