<form
  mat-dialog-content
  class="login"
  [formGroup]="signinForm"
  #formDirective="ngForm"
  (ngSubmit)="submit(formDirective)"
>
  <h2>Anmelden</h2>
  <mat-form-field>
    <mat-label>Benutzername</mat-label>
    <input type="text" matInput formControlName="username" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Passwort</mat-label>
    <input type="password" matInput formControlName="password" />
  </mat-form-field>
  <mat-dialog-actions *ngIf="!isSigningIn">
    <button mat-flat-button color="primary" type="submit">Anmelden</button>
    <button mat-flat-button color="basic" mat-dialog-close>Zurück</button>
  </mat-dialog-actions>

  <mat-dialog-actions *ngIf="isSigningIn">
    <app-loading></app-loading>
  </mat-dialog-actions>
</form>
