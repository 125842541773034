import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

const defaultPicture = '/assets/images/Platzhalter.png';

@Pipe({
  name: 'picturePathConverter',
})
export class PicturePathConverterPipe implements PipeTransform {
  transform(path: string): string {
    if (path == null || path == '') return defaultPicture;
    if (path.startsWith('/images/'))
      return environment.apiBaseUrl + path.substring(1);
    if (
      path.search('https://') !== -1 ||
      path.search('http://') !== -1 ||
      path.startsWith('data:image')
    )
      return path;
    return defaultPicture;
  }
}
