import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClickUtilService {
  documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();

  constructor() {}
}
