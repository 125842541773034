import { CommonModule } from '@angular/common';
import {
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModules } from 'src/utils/ts/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoadingComponent } from './components/loading/loading.component';
import { LoginComponent } from './components/login/login.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AboutComponent } from './pages/about/about.component';
import { ArticleComponent } from './pages/article/article.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { LoginPageComponent } from './pages/login/login.component';
import { NewsComponent } from './pages/news/news.component';
import { PageNotFoundComponent } from './pages/not-found/not-found.component';
import { PrivacyStatementComponent } from './pages/privacy-statement/privacy-statement.component';
import { ImageViewerComponent } from './pages/article/image-viewer/image-viewer.component';
import { PictureUploadComponent } from './pages/article/picture-upload/picture-upload.component';

import { AuthInterceptorService } from './services/_helpers/auth.interceptor';

import { ErrorInterceptor } from './services/_helpers/error.interceptor';

import { PicturePathConverterPipe } from './services/_pipe/picture-path-converter.pipe';
import { UnsafeHTMLPipe } from './services/_pipe/unsafe-html.pipe';

import { PendingChangesGuard } from './services/_guards/pending-changes.guard';

// import { QuillModule } from 'ngx-quill';
// import { MatQuillModule } from '../utils/mat-quill/mat-quill-modules';
import { GoogleMapsDemoModule } from './pages/home/approch-map/googe-maps.module';
import { CarouselComponent } from './pages/home/carousel/carousel.component';
import { CookieconsentComponent } from './components/cookieconsent/cookieconsent.component';
import { ArticleHeadlineComponent } from './pages/article/article-headline/article-headline.component';
import { ArticleSublineComponent } from './pages/article/article-subline/article-subline.component';
import { ArticleTagsComponent } from './pages/article/article-tags/article-tags.component';
import { ArticleContentComponent } from './pages/article/article-content/article-content.component';
import { ArticleBaseComponent } from './pages/article/article-base/article-base.component';
import { PlatzhalterPipe } from './services/_pipe/platzhalter.pipe';
import { ABxJsonpInterceptor } from './services/_helpers/abx-jsonp.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    SidebarComponent,
    HomeComponent,
    AboutComponent,
    PageNotFoundComponent,
    ContactComponent,
    NewsComponent,
    CalendarComponent,
    PrivacyStatementComponent,
    ImprintComponent,
    ArticleComponent,
    LoadingComponent,
    LoginComponent,
    LoginPageComponent,
    PicturePathConverterPipe,
    PictureUploadComponent,
    UnsafeHTMLPipe,
    ImageViewerComponent,
    CarouselComponent,
    CookieconsentComponent,
    ArticleHeadlineComponent,
    ArticleSublineComponent,
    ArticleTagsComponent,
    ArticleContentComponent,
    ArticleBaseComponent,
    PlatzhalterPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModules,
    GoogleMapsDemoModule,
    // QuillModule.forRoot(),
    // MatQuillModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ABxJsonpInterceptor,
      multi: true,
    },
    PendingChangesGuard,
    PicturePathConverterPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
