<div #inputWatcher>
  <h1 *ngIf="!isEditing" (click)="edit()">
    <mat-icon class="editIcon" *ngIf="editMode" (click)="edit()">edit</mat-icon
    ><span>{{ value | platzhalter }}</span>
  </h1>
  <div class="input-wrapper" *ngIf="isEditing">
    <mat-icon class="editIcon cancel" (click)="cancel()">close</mat-icon>
    <input
      type="text"
      autofocus="autofocus"
      placeholder="Überschrift einfügen"
      (keydown.Enter)="processChanges()"
      (keydown.Meta.Enter)="processChanges()"
      (keydown.Escape)="cancel()"
      [(ngModel)]="pendingValue"
    />
  </div>
</div>
