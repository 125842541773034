import { Component, Input, OnInit } from '@angular/core';
import { Article } from 'src/app/interfaces/article';
import { ArticleService } from 'src/app/services/article.service';
import { PicturePathConverterPipe } from 'src/app/services/_pipe/picture-path-converter.pipe';
import { MessagingService } from 'src/app/services/messaging.service';

const AUTO_SLIDE_INTERVAL = 8000;
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  articles: Article[] | undefined = undefined;
  interval!: any;

  selectedIndex = 0;

  constructor(
    private articleService: ArticleService,
    public picturePathConverter: PicturePathConverterPipe,
    private message: MessagingService
  ) {}

  ngOnInit(): void {
    this.loadTopArticles();
    this.enableAutorotate();
  }

  loadTopArticles(): void {
    this.articleService.getTopArticles().subscribe(
      (articles) => {
        this.articles = articles;
      },
      (err) => {
        this.message.defaultError();
        return;
      }
    );
  }

  selectImage(index: number): void {
    this.selectedIndex = index;
  }

  onPrevClick(): void {
    this.selectedIndex--;
    if (this.selectedIndex < 0)
      this.selectedIndex =
        this.articles?.length === undefined ? 0 : this.articles.length - 1;
  }

  onNextClick(): void {
    this.selectedIndex++;
    if (this.articles && this.selectedIndex > this.articles.length - 1)
      this.selectedIndex = 0;
  }

  enableAutorotate(): void {
    this.interval = setInterval(() => {
      this.onNextClick();
    }, AUTO_SLIDE_INTERVAL);
  }

  disableAutorotate(): void {
    clearInterval(this.interval);
  }
}
