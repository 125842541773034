import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginComponent } from '../../components/login/login.component';

@Component({
  selector: 'app-login-page',
  template: ``,
})
export class LoginPageComponent implements OnInit {
  constructor(public dialog: MatDialog, public router: Router) {}

  ngOnInit(): void {
    this.dialog.open(LoginComponent);
    const queryParams = this.router.parseUrl(this.router.url).queryParams;

    this.router.navigate(['/home']);

    this.dialog.afterAllClosed.subscribe(() => {
      if (queryParams.returnUrl) {
        this.router.navigateByUrl(queryParams.returnUrl);
      }
    });
  }
}
