<main>
  <aside>
    <h1>Über Uns</h1>
    <h3>Unsere Meilensteine</h3>

    <div class="text-box">
      <p>
        Mit dem direkt am Lippesee liegendem Gelände haben wir besten Zugang zu
        dem Wasser(sport). Und das seit mehr als zwei Jahrzehnten! Durch den
        2016 gegründeten Windsurfverein wurde dies noch einmal fundiert. Von
        jung bis alt, von klein bis groß. Bei uns findet jeder die richtigen
        Aktivitäten. Vom Regattasport über den Breitensport bis hin zum
        gemütlichen Beisammensein am Wochenende oder nach der Arbeit.
      </p>
      <div class="line"></div>
    </div>
  </aside>
  <div class="container">
    <section class="image-container one left">
      <h3>100 Mitglieder</h3>
      <span class="year">2020</span>
      <div class="image">
        <p>
          Nicht einmal bis zum fünfjährigen Jubiläum hat es gedauert, bis die
          100 Mitglieder Marke übertroffen wurde!
        </p>
        <mat-icon>info_outline</mat-icon>
      </div>
    </section>

    <section class="connector one">
      <div></div>
    </section>

    <section class="image-container two right">
      <h3>Erster Padercup</h3>
      <span class="year">2017</span>
      <div class="image">
        <p>
          Was würden die Windsurfer ohne ihre traditionsreiche Heimregatta
          machen? Wir wissen es auch nicht! Denn direkt nach dem Start des
          Portbaus ging 2017 der PaderCup mit frischen Winden wieder an den
          Start.
        </p>
        <mat-icon>info_outline</mat-icon>
      </div>
    </section>

    <section class="connector two">
      <div></div>
    </section>

    <section class="image-container three left">
      <h3>Portbau</h3>
      <span class="year">2017</span>
      <div class="image">
        <p>
          Was wäre ein Verein ohne seine eigene Bleibe. Aber gerade, um das
          gesamte Material nicht jede Woche aufs Neue an den See bringen zu
          müssen, brauchten wir einen Port! Und so startete 2017 der Portbau.
        </p>
        <mat-icon>info_outline</mat-icon>
      </div>
    </section>

    <section class="connector three">
      <div></div>
    </section>

    <section class="image-container four right">
      <h3>Gründung</h3>
      <span class="year">2016</span>
      <div class="image">
        <p>
          2016 war es so weit! Die aktiven Surfer des Lippesee's haben sich im
          neuen Just Windsurfing Paderborn zusammengeschlossen. Und mit viel
          Schweiß und Herzblut ist aus den ursprünglichen zwei Dutzend
          Wassersportbegeisterten ein großartiges Vereinsleben entstanden.
        </p>
        <mat-icon>info_outline</mat-icon>
      </div>
    </section>
  </div>
</main>
