import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ArticleTags } from 'src/app/interfaces/article';
import { ClickUtilService } from 'src/app/services/click-util.service';
import { TagService } from 'src/app/services/tag.service';
import { ArticleBaseComponent } from '../article-base/article-base.component';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-article-tags',
  templateUrl: './article-tags.component.html',
  styleUrls: ['./article-tags.component.scss'],
})
export class ArticleTagsComponent
  extends ArticleBaseComponent
  implements AfterViewInit, OnInit
{
  @Input() tags?: ArticleTags[] = undefined;
  @ViewChild('inputWatcher') _elem!: ElementRef;
  @ViewChild('matSelect') _SelectElem!: ElementRef;
  allTags!: ArticleTags[];

  constructor(
    private tagService: TagService,
    clickUtilService: ClickUtilService,
    private message: MessagingService
  ) {
    super(clickUtilService);
  }

  ngOnInit(): void {
    if (this.tags == undefined) this.tags = [{ name: '', id: -1, color: '' }];
    this.initialize();
  }

  ngAfterViewInit() {
    this.elem = this._elem;
  }

  changeMode() {
    if (!this.editMode) return;
    this.loadAllTags();
  }

  loadAllTags() {
    this.tagService.getTags().subscribe(
      (tags: ArticleTags[]) => {
        this.allTags = tags;
        let currTag = this.tags;
        if (!currTag) return;
        this.tags = this.allTags.filter(
          (x) => currTag?.find((y) => y.id == x.id) !== undefined
        );
        this.edit();
        this.pendingValue = this.tags;
      },
      (err) => {
        this.message.defaultError();
      }
    );
  }

  public documentClickListener(target: any): void {
    if (this.isEditing) {
      if (
        !this.elem?.nativeElement.contains(target) &&
        !(
          (target.parentElement != undefined &&
            (target.parentElement?.id as String).includes('mat-option')) || // check the "background" of the popup was clicked
          (target.parentElement != undefined &&
            (target.parentElement.id as String).includes('mat-select')) || // check if the checkbox of the popup was clicked
          (target.parentElement != undefined &&
            target.parentElement.parentElement != undefined &&
            (target.parentElement.parentElement.id as String).includes(
              'mat-option' // check if the text of the popup was clicked
            ))
        )
      )
        this.processChanges();
    }
  }
}
