import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NavButton } from 'src/app/interfaces/navigation-button';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  buttons: NavButton[] = NAV_BUTTONS.filter(
    (button) => button.location == 'nav'
  );

  bigScreenSize: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((state) => {
        if (state.matches) {
          this.bigScreenSize = true;
        } else {
          this.bigScreenSize = false;
        }
      });
  }
}

export const NAV_BUTTONS: NavButton[] = [
  { name: 'Home', link: '/home', location: 'nav' },
  { name: 'News', link: '/news', location: 'nav' },
  { name: 'Über uns', link: '/about', location: 'nav' },
  { name: 'Kontakt', link: '/contact', location: 'nav' },
  { name: 'Impressum', link: '/imprint', location: 'side' },
  { name: 'Datenschutzerklärung', link: '/privacyStatement', location: 'side' },
];
