<main>
  <header>
    <h2>Kontaktdaten</h2>
    <div class="info-container">
      <div class="info-wrapper">
        <h3>E-Mail</h3>
        <span>{{ imprintData.email }}</span>
      </div>
      <div class="info-wrapper">
        <h3>Telefon</h3>
        <span>{{ imprintData.phone }}</span>
      </div>
      <div class="info-wrapper">
        <h3>Anschrift</h3>
        <span>
          {{ imprintData.street }}<br />
          {{ imprintData.city }}
        </span>
      </div>
    </div>
  </header>

  <section class="form-container">
    <h1>Kontaktiere <span class="white-color">Uns</span></h1>
    <form
      [formGroup]="contactForm"
      #formDirective="ngForm"
      (ngSubmit)="onSubmit(formDirective)"
    >
      <mat-form-field>
        <mat-label>Name: </mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-error *ngIf="contactForm.controls.name.invalid">
          {{ getNameErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>E-Mail: </mat-label>
        <input matInput type="email" formControlName="email" />
        <mat-error *ngIf="contactForm.controls.email.invalid">
          {{ getEmailErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Telefon: </mat-label>
        <input matInput formControlName="phone" />
        <mat-error *ngIf="contactForm.controls.phone.invalid">
          {{ getPhoneErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nachricht: </mat-label>
        <textarea matInput formControlName="message"></textarea>
        <mat-error *ngIf="contactForm.controls.message.invalid">
          {{ getMessageErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <div class="button-wrapper">
        <button aria-label="Nachricht senden" class="button" *ngIf="!isSending">
          <span>Senden</span>
          <div class="arrow-right"></div>
        </button>

        <app-loading *ngIf="isSending"></app-loading>
      </div>
    </form>
  </section>
</main>
