import { Component, OnInit } from '@angular/core';
import { BackgroundChangerService } from 'src/app/services/background-changer.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  providers: [BackgroundChangerService],
})
export class AboutComponent {
  constructor(private background: BackgroundChangerService) {}
}
