<div #inputWatcher>
  <h2 *ngIf="!isEditing" (click)="edit()">
    <mat-icon class="editIcon" *ngIf="editMode" (click)="edit()">edit</mat-icon
    >{{ value | platzhalter }}
  </h2>
  <div class="input-wrapper" *ngIf="isEditing">
    <mat-icon class="editIcon cancel" (click)="cancel()">close</mat-icon>
    <textarea
      type="text"
      class="input"
      autofocus="autofocus"
      (keydown.Enter)="processChanges()"
      (keydown.Meta.Enter)="processChanges()"
      (keydown.Escape)="cancel()"
      [(ngModel)]="pendingValue"
      wrap="soft"
      rows="1"
    ></textarea>
  </div>
</div>
