import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  constructor(private _snackBar: MatSnackBar) {}

  defaultError() {
    this.error('Ein Fehler ist aufgetreten! Versuche es später erneut.');
  }

  error(message: string, duration = 5000) {
    this._snackBar.open(message, 'OK', {
      duration: duration,
      panelClass: ['red-snackbar'],
    });
  }

  success(message: string, duration = 3000) {
    this._snackBar.open(message, 'OK', {
      duration: duration,
      panelClass: ['green-snackbar'],
    });
  }

  info(message: string, duration = 3000) {
    this._snackBar.open(message, 'OK', {
      duration: duration,
      panelClass: ['orange-snackbar'],
    });
  }
}
