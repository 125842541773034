import { Component } from '@angular/core';
import { BackgroundChangerService } from 'src/app/services/background-changer.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [BackgroundChangerService],
})
export class HomeComponent {
  public showCookieRelatedItems: boolean = false;

  constructor(
    private background: BackgroundChangerService,
    private tokenService: TokenStorageService
  ) {
    this.tokenService.userAcceptedCookieConsent.subscribe((status) => {
      if (status) this.showCookieRelatedItems = true;
      else this.showCookieRelatedItems = false;
    });
  }
}
