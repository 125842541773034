import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NavButton } from 'src/app/interfaces/navigation-button';
import { UserService } from 'src/app/services/user.service';

import { LoginComponent } from '../login/login.component';
import { NAV_BUTTONS } from '../navigation/navigation.component';

const ANIMATIONS: AnimationTriggerMetadata[] = [
  trigger('animateOpacity', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('500ms', style({ opacity: 0.5 })),
    ]),
    transition(':leave', [animate('250ms', style({ opacity: 0 }))]),
  ]),
  trigger('slideInFromRight', [
    transition(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate('500ms', style({ transform: 'translateX(0)' })),
    ]),
    transition(':leave', [
      animate('500ms', style({ transform: 'translateX(100%)' })),
    ]),
  ]),
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: ANIMATIONS,
})
export class SidebarComponent implements AfterViewInit {
  navEnd: Observable<NavigationEnd>;
  isOpen: boolean = false;

  supportURL = 'https://github.com/RichardSprenger/JWS-Website-Issue-Tracking';
  showLoginButton: boolean = false;

  today = new Date();

  @Input() bigScreenSize: boolean = false;

  navBarButtons: NavButton[] = NAV_BUTTONS.filter(
    (button) => button.location == 'nav'
  );
  sideBarButtons: NavButton[] = NAV_BUTTONS.filter(
    (button) => button.location == 'side'
  );
  userButtons = new Set<NavButton>();

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public user: UserService
  ) {
    this.navEnd = router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  ngAfterViewInit(): void {
    this.navEnd.subscribe(() => this.close());
    this.user.isLoggedIn.subscribe((status) => {
      if (status) {
        this.checkPermissions();
        this.showLoginButton = false;
      } else {
        this.showLoginButton = true;
      }
    });
  }

  checkPermissions(): void {
    if (!this.user) return;
    const routerSnapshot = this.router.routerState.snapshot;

    // ToDo: Add when functionality is there
    // this.userButtons.add(NB_calender);

    if (this.user.getRoles().length == 0) return;

    if (this.user.isVorstand()) {
      this.userButtons.add(NB_newArticle);
      if (routerSnapshot.url.match('^/article/[0-9]*$')) {
        // ToDo: Add when functionality is there
        // this.userButtons.add(NB_deleteArticle);
        this.userButtons.add(NB_editArticle);
      }
    }

    if (this.user.isAuthor()) {
      // ToDo: should authors only be able to edit own articles?
      if (routerSnapshot.url.match('^/article/[0-9]*$'))
        this.userButtons.add(NB_editArticle);
      this.userButtons.add(NB_newArticle);
    }

    this.userButtons.forEach((button) => {
      if (Array.isArray(button.link)) {
        button.link[2] = routerSnapshot.url.split('/')[2];
        button.link = button.link.join('/');
      }
    });
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  close() {
    this.isOpen = false;
  }

  login() {
    this.dialog
      .open(LoginComponent)
      .afterClosed()
      .subscribe(() => this.checkPermissions());
  }

  logout() {
    this.user.signOut();
    this.userButtons.clear();
  }
}

const NB_calender: NavButton = {
  name: 'Event-Kalender',
  link: '/calendar',
};
const NB_newArticle: NavButton = {
  name: 'Neuer Artikel',
  link: '/article/new',
};
const NB_editArticle: NavButton = {
  name: 'Diesen Artikel bearbeiten',
  link: ['/', 'article', '', 'edit'],
};
const NB_deleteArticle: NavButton = {
  name: 'Diesen Artikel löschen',
  link: ['/', 'article', '', 'delete'],
};
