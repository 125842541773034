import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ClickUtilService } from 'src/app/services/click-util.service';

@Component({
  selector: 'app-article-base',
  template: '',
  styleUrls: ['./article-base.component.scss'],
})
export class ArticleBaseComponent implements OnInit {
  @Input() value!: any;
  @Input() editMode: boolean = false;
  @Output() valueChangeEvents: EventEmitter<any>;
  @Output() isInEdit: EventEmitter<boolean>;
  public pendingValue: any;
  public isEditing: boolean = false;
  public clickCounter: number = -1;
  public elem!: ElementRef;

  constructor(public clickUtilService: ClickUtilService) {
    this.valueChangeEvents = new EventEmitter();
    this.isInEdit = new EventEmitter();
    this.pendingValue = '';
  }

  ngOnInit(): void {
    this.initialize();
  }

  public initialize() {
    this.pendingValue = this.value;
    if (this.editMode)
      this.clickUtilService.documentClickedTarget.subscribe(
        (target: HTMLElement) => {
          this.documentClickListener(target);
        }
      );
  }

  public edit() {
    if (!this.editMode) return;
    this.isInEdit.emit(true);
    this.isEditing = true;
    this.clickCounter = 0;
  }

  public documentClickListener(target: any): void {
    if (this.isEditing) {
      if (!this.elem?.nativeElement.contains(target) && this.clickCounter >= 1)
        this.processChanges();
      if (this.clickCounter == 0) this.clickCounter = 1;
    }
  }

  public cancel(): void {
    this.isInEdit.emit(false);
    this.isEditing = false;
  }

  public processChanges(): void {
    if (this.pendingValue !== this.value) {
      this.isInEdit.emit(false);
      this.valueChangeEvents.emit(this.pendingValue);
    }
    this.cancel();
  }
}
