<mat-toolbar color="primary">
  <!-- TODO: potentially more accesabillity? -->
  <!-- TODO: logo could have a better focus-state-->
  <a class="icon" routerLink="/home">
    <img src="/assets/images/logo-text.svg" alt="Logo" />
  </a>

  <ng-container *ngIf="bigScreenSize">
    <a
      *ngFor="let button of buttons"
      mat-flat-button
      color="primary"
      [routerLink]="[button.link]"
      [routerLinkActive]="['active']"
    >
      {{ button.name }}
    </a>
  </ng-container>

  <!-- TODO: this aria-labele might need to change -->
  <button mat-icon-button aria-label="Sidebar" (click)="sidebar.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
<app-sidebar #sidebar [bigScreenSize]="bigScreenSize"></app-sidebar>
