import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AUTH_KEY } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  private _userAcceptedCookieConsent = new BehaviorSubject<boolean>(false);

  constructor() {}

  public set setUserAcceptedCookieConsent(cookieConsent: boolean) {
    this._userAcceptedCookieConsent.next(cookieConsent);
  }

  public get userAcceptedCookieConsent(): Observable<boolean> {
    return this._userAcceptedCookieConsent.asObservable();
  }

  public getCookie(name: string): string | null {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    if (!this._userAcceptedCookieConsent.value && name == AUTH_KEY) {
      const token = window.sessionStorage.getItem(AUTH_KEY);
      return token;
    }
    return null;
  }

  public deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }

  public setCookie(
    name: string,
    value: string,
    expireDays: number,
    path: string = ''
  ) {
    this.userAcceptedCookieConsent
      .subscribe((status) => {
        if (status) {
          let d: Date = new Date();
          d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
          let expires: string = 'expires=' + d.toUTCString();
          document.cookie =
            name +
            '=' +
            value +
            '; ' +
            expires +
            (path.length > 0 ? '; path=' + path : '');
        } else if (name == AUTH_KEY) {
          window.sessionStorage.setItem(AUTH_KEY, value);
        }
      })
      .unsubscribe();
  }
}
