<main>
  <h1>Impressum</h1>

  <h3>Angaben gemäß § 5 TMG</h3>
  <p>
    Just Windsurfing Paderborn e.V.<br />
    {{ imprint.street }}<br />
    {{ imprint.city }}
  </p>

  <p>
    Registereintrag {{ imprint.reg_nr }}<br />
    Registergericht {{ imprint.reg_place }}
  </p>

  <p>
    <strong>Vertreten durch den Vorstand:</strong><br />
    {{ imprint.chairman }}<br />
    {{ imprint.second_chairman }}<br />
    {{ imprint.management }}<br />
    {{ imprint.treasurer }}
  </p>

  <h3>Kontakt</h3>
  <p>
    Telefon: {{ imprint.phone }}<br />
    E-Mail: {{ imprint.email }}
  </p>

  <h3>Redaktionell verantwortlich</h3>
  <p>
    {{ imprint.editor }}<br />
    {{ imprint.editor_street }}<br />
    {{ imprint.editor_city }}
  </p>

  <div class="space"></div>
  <h3>Haftung für Inhalte</h3>
  <p>
    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
    diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10
    TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
    oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
    forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen
    zur Entfernung oder Sperrung der Nutzung von Informationen nach den
    allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
    ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
    Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
  </p>

  <h3>Haftung für Links</h3>
  <p>
    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte
    wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
    keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
    jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
    Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
    überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
    erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
    Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
    entfernen.
  </p>

  <h3>Urheberrecht</h3>
  <p>
    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
    unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
    Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
    Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
    privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
    dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
    Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
    gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
    aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
    entfernen.
  </p>

  <h3>Kontaktformular</h3>
  <p>
    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
    Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
    Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
    Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
    Einwilligung weiter.
  </p>

  <h3>Widerspruch Werbe-Mails</h3>
  <p>
    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
    Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung
    und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
    Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
    unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
  </p>
</main>
