import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClickUtilService } from './services/click-util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Just Windsurfing Paderborn e.V.';
  dispalyEnv = false;
  envString = '';

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.clickUtilService.documentClickedTarget.next(event.target);
  }

  constructor(private clickUtilService: ClickUtilService) {
    if (environment.envString != '') {
      this.dispalyEnv = true;
      this.envString = environment.envString;
    }
  }
}
